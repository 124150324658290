import React from 'react';
 import Hero from '../components/home/hero';
import About from '../components/home/about';
import Info from '../components/home/info';
import Featuredproperties from '../components/home/featuredproperties';
import Blog from '../components/home/blogs';
import Connected from '../components/home/connected';
import QuickFacts from '../components/home/quickFacts';
import FlatsPlans from '../components/home/flatsPlans';
import Offering from '../components/home/offering';
import Gallery from '../components/home/gallery';
import Map from '../components/home/map';
import { Link, useLocation } from "react-router-dom";
import MenuDrawer from "../components/shared/drawer";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { windowScroll } from "../components/utils/windowScroll";
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import ImageIcons from '../components/imageComponent/ImageIcons';
import { IoCall, IoCheckmarkDoneSharp, IoSearch } from "react-icons/io5";
import ScrollToTopButton from "../components/utils/bottomToTopScroll";
// import { useLocation } from "react-router-dom";
import { BsTwitterX } from "react-icons/bs";
import { FaPinterestP } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
// import Ourservice from '../components/home/ourservices';
// import Awesomefeatures from '../components/home/awesomefeatures';
// import Testimonial from '../components/shared/testimonials';
// import SpecialFeatures from '../components/home/specialFeatures';
// import Welcome from '../components/home/welcome';

const Home2 = () => {

  const currentYear = new Date().getFullYear();
  const location = useLocation();

  const isActive = (handle) => {
    return location.pathname === handle ? 'active' : '';
  }
  return (





    <>
      <header className="relative z-50 white_header">

        <div className="bg-primary py-[15px] bg-[#359dd0]">
          <div className="container">
            <div className="flex justify-between max-md:flex-wrap max-md:justify-center gap-[15px]">
              <ul className="flex items-center sm:gap-[30px] gap-[15px]">
                <li className="flex items-center gap-[5px]">
                  <Link className="text-white hover:text-[#ffe2ae]" to="tel:+919803596035"><IoCall className="inline-block text-[22px]" /> +(91) 9803596035</Link>
                </li>
                <li
                  className="item cursor-pointer text-white duration-500 hover:text-[#ffe2ae] gap-[5px] flex items-center"
                // onClick={() => CopyToClipboard(mail)}
                >
                  <GrMail className="inline-block text-[22px]" />
                  <div>Email Us</div>
                  {/* <FaRegCopy /> */}
                </li>
              </ul>

              <div className="flex items-center gap-[20px] max-sm:w-full">
                <div className="relative rounded-[3px] max-md:w-full">
                  <input
                    type="search"
                    placeholder="Search...."
                    className="h-[40px] pl-[12px] pr-[50px] focus:outline-none lg:w-[270px] max-md:w-full rounded-l-[2px]" />

                  <button type="button" className="h-[40px] border-l border-[#359dd0] w-[40px] bg-[#fff] hover:bg-[#d2ac67] text-[#359dd0] hover:text-white absolute top-0 right-0 flex justify-center items-center duration-500">
                    <IoSearch className="text-[21px]" /></button>
                </div>
                <ul className="flex gap-[6px] max-lg:hidden">
                  {socialMediaData.map((data, i) => (
                    <li key={i}>
                      <Link
                        to={data.url}
                        aria-label={data.aria}
                        role="button"
                        target="_blank"
                        className="bg-white text-[#359dd0] hover:text-[#fff] hover:bg-[#d2ac67] w-[35px] h-[35px] text-[15px] rounded-[50%] flex justify-center items-center"
                      >
                        <span>{data.icon}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="max-lg:py-[15px]">
          <div className="container">
            <div className="flex justify-end items-center lg:h-auto relative">
              <Link to="/" className="flex items-center py-[15px] lg:px-[30px] px-[25px] absolute left-0 top-0 max-lg:top-[-15px] bg-[#fff] rounded-b-[20px] ">
                <img
                  src={ImageIcons.logo}
                  className="lg:max-h-[150px] sm:max-h-[110px] max-h-[100px]"
                  alt='Logo'
                />
              </Link>

              <nav className='lg:block hidden '>
                <ul className=" flex items-center lg:flex-nowrap flex-wrap lg:justify-center justify-start justify-left">
                  {data.navLinks.map((link, i) => (
                    <li key={i} className="relative group lg:w-auto w-full xl:ml-[50px] lg:ml-[40px] ml-[30px]">

                      <Link to={link.handle} className={`text-[#000] hover:text-[#359dd0] 2xl:text-[18px] font-medium xl:text-[17px] lg:inline-block block w-full lg:py-[35px] py-[10px] ${isActive(link.handle)}`}>{link.title}</Link>
                      {link.subLinks && (<div className="transform origin-[bottom_center] duration-500 scale-y-0 invisible opacity-0 visibil translate-z-100 group-hover:scale-y-100 group-hover:translate-z-0 group-hover:visible group-hover:opacity-100 content=[''] absolute bottom-0 left-[15px] border-transparent !border-b-white border-[15px]"></div>)}

                      {link.subLinks && (
                        <ul className="duration-500 origin-[top_center] overflow-hidden transform scale-y-0 invisible opacity-0 visibil translate-z-100 group-hover:scale-y-100 group-hover:translate-z-0 group-hover:visible group-hover:opacity-100 left-0 rounded-b-[10px] shadow-[0px_10px_15px_0px_rgba(0,0,0,0.2)] grid bg-white text-black md:absolute relative top-[100%] md:w-[250px] w-[100%] z-50 ">
                          {link.subLinks.map((item, i) => (
                            <li key={i} className="w-fill">
                              <Link to={item.handle} className={`border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#359dd0] hover:text-white px-[20px] py-[12px] `}>{item.title}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="inline-block lg:hidden">
                <MenuDrawer navLinks={data.navLinks} />
              </div>
            </div>
          </div>
        </div>
      </header >
      <Hero />
      <About />
      <Featuredproperties />
      <Connected />
      <Info />
      <QuickFacts />
      <FlatsPlans />
      <Offering />
      <Gallery />
      <Blog />
      <Map />

      < footer className="border-t-4 border-[#359dd0]">
        <div>
          <div className="container bg-no-repeat bg-bottom bg-contain" style={{ backgroundImage: `url(${ImageIcons.footerBg})` }}>
            <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[30px] md:py-[50px] py-[30px]">
              <div>
                <img className="max-h-[130px] mb-[15px]" src={ImageIcons.logo} alt="" />
                <p className="mb-0">Angan homes is a company which specialises in offering modern and comfortable apartments which are located in exquisite locations. We ensure an enjoyable living experience to our customers with our exceptional customer services.</p>
              </div>

              {footerLinks.map((link, i) => (
                <div key={i}>
                  <h3 className="t font-semibold pb-[8px] mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#333]" >{link.title}</h3>
                  <ul className="grid gap-[10px] sm:gap-[10px] md:gap-[12px]">
                    {link.subLinks.map((item, i) => (
                      <li key={i}>

                        <Link to={item.handle} className={`text-[16px] hover:text-[#359dd0] flex items-center  ${isActive(item.handle)} `} onClick={windowScroll} ><IoCheckmarkDoneSharp className="mr-[6px] secondary_text" /> {item.link}</Link>

                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div>
                <h3 className=" font-semibold	pb-[8px] mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#333]">Contact Info</h3>
                <div className="grid gap-[10px] md:gap-[15px]">
                  <div className="flex gap-[10px] items-start">
                    <FaLocationDot className=" text-[30px] min-w-[30px]" />
                    <span className="text-[16px] ">{data.address}</span>
                  </div>
                  <div>
                    <Link to={`tel:${data.mobileNo}`} className="text-[16px]  hover:text-[#359dd0] duration-500 flex gap-[10px] items-center"><MdCall className="inline-block text-[30px] min-w-[30px]" />{data.mobileNo}</Link>
                  </div>
                  <div>
                    <Link to={`mailto:${data.mail}`} className="text-[16px] hover:text-[#359dd0] duration-500 flex gap-[10px] items-center">
                      <MdEmail className="inline-block text-[30px] min-w-[30px]" />{data.mail}</Link>
                  </div>
                </div>
              </div>

              <div>
                <img className="max-w-[120px]" src={ImageIcons.locationScan} alt="" />

                <div className="grid gap-[12px] sm:gap-[15px] md:gap-[20px] mt-[20px]">
                  <div className="flex">
                    <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#000] hover:text-[#fff] w-[40px] h-[40px] rounded-full border border-[#000] hover:border-[#359dd0] hover:bg-[#359dd0] flex justify-center items-center'> <FaFacebookF /></Link>
                    <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#000] hover:text-[#fff] w-[40px] h-[40px] rounded-full border border-[#000] hover:border-[#359dd0] hover:bg-[#359dd0] flex justify-center items-center'> <FaTwitter /></Link>
                    <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#000] hover:text-[#fff] w-[40px] h-[40px] rounded-full border border-[#000] hover:border-[#359dd0] hover:bg-[#359dd0] flex justify-center items-center'> <FaLinkedinIn /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#fff] border-t border">
          <div className="container">
            <div className="flex md:justify-between justify-center items-center md:flex-nowrap flex-wrap py-[15px] gap-[10px]">
              <p className="text-[16px] mb-0 text-center">© Copyright {currentYear} by Aangan Homes. All Right Reserved.</p>

              <div className="md:text-left text-center md:w-auto w-full">
                <Link className={`lg:mx-[20px] mx-[10px] my-[8px] inline-block hover:text-[#359dd0] ${isActive("/privacy-policy")}`} to="/privacy-policy" >Privacy Policy</Link>
                <Link className={`lg:mx-[20px] mx-[10px] my-[8px] inline-block hover:text-[#359dd0] ${isActive("/terms-conditions")}`} to="/terms-conditions" >Terms & Conditions</Link>
                <Link className={`lg:mx-[20px] mx-[10px] my-[8px] inline-block hover:text-[#359dd0] ${isActive("/refund-policy")}`} to="/refund-policy" >Refund Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer >

      <ScrollToTopButton />

    </>
  )
}

const socialMediaData = [
  {
    url: "https://www.facebook.com/",
    icon: <FaFacebookF />,
  },
  {
    url: "https://twitter.com/",
    icon: <BsTwitterX />,
  },
  {
    url: "https://www.linkedin.com/",
    icon: <FaLinkedinIn />,
  },
  {
    url: "https://in.pinterest.com/",
    icon: <FaPinterestP />,
  },
  {
    url: "https://www.instagram.com",
    icon: <BsInstagram />,
  },
];

const data = {
  mobileNo: "9988599485",
  mail: "info@aanganhomes.com",
  address: "High Ground Road, (Opposite Apple Resort) Chandigarh-Patiala Highway, Zirakpur",
  navLinks: [
    {
      title: "Home",
      handle: "/"
    },
    {
      title: "Properties",
      handle: "/properties",
      subLinks: [
        {
          title: "3 BHK Flats",
          handle: "/3-bhk-flats"
        }
      ]
    },
    {
      title: "Layout",
      handle: "/design-and-layout"
    },
    {
      title: "About Us",
      handle: "/about-us"
    },
    {
      title: "Contact Us",
      handle: "/contact-us"
    },
  ]
}

const footerLinks = [
  {
    title: "Useful Links",
    subLinks: [
      {
        link: "Home",
        handle: "/"
      },

      {
        link: "About Us",
        handle: "/about-us"
      },
      {
        link: "Blog",
        handle: "/blog"
      },
      {
        link: "Properties",
        handle: "/properties"
      },
      {
        link: "Contact Us",
        handle: "/contact-us"
      },
      {
        link: "Gallery",
        handle: "/gallery"
      },
      {
        link: "Sitemap",
        handle: "/sitemap"
      },
    ]
  }
];

export default Home2
