import React from 'react';
import ImageIcons from '../imageComponent/ImageIcons';
import { GiBullseye } from "react-icons/gi";
import { FaEye } from 'react-icons/fa';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';

const Aboutus = () => {
    return (
        <>
            <section>
                <div className='container'>
                    <div className='max-w-[1000px] mx-auto text-center mb-[40px]'>
                        <p className='mb-0 text-[18px]'>Founded in 2024, Angan homes is devoted to providing modern living with our elegantly designed flats which are a complete blend of comfort, convenience, and elegance. Our 3 BHK independent flats are located in prime locations surrounded by a lush green environment. We are committed to providing you with the best urban living, where you live close to nature. Get residence of your dreams with Angan homes real estate company, as you will not only get a house to live in but many other necessary facilities which you may have never heard of or experienced via the services of a real estate company.
                        </p>
                    </div>

                    <div>
                        <img className='' src={ImageIcons.banner1} alt='' />
                    </div>

                    <div className='grid lg:grid-cols-2 gap-[20px] lg:mx-[50px] mx-0 lg:-mt-[100px] mt-[40px]'>
                        <div className='shadow-[0_0_35px_rgba(140,152,164,0.30)] p-[30px] bg-white group'>
                            <span className='w-[80px] h-[80px] flex items-center justify-center rounded-full text-[#359dd0] border-[#359dd0] group-hover:bg-[#d2ac67] group-hover:border-[#d2ac67] group-hover:text-white duration-500 border text-[40px] mb-[15px]'><GiBullseye /></span>
                            <h2>Our Vision</h2>
                            <p className='mb-0'>At Angan homes, our vision is to stay abreast and become the prominent choice of our customers by developing and building picturesque and green spaces, which will provide you with a mix of country and urban life. We are committed to making your renting, buying and selling process easy and convenient throughout your journey with us. Our team is passionate about delivering value services to our customers, as we aspire to create a sustainable and vibrant community where you feel living in a countryside area offering excellence and innovation.</p>
                        </div>

                        <div className='shadow-[0_0_35px_rgba(140,152,164,0.30)] p-[30px] bg-white group'>
                            <span className='w-[80px] h-[80px] flex items-center justify-center rounded-full text-[#359dd0] border-[#359dd0] group-hover:bg-[#d2ac67] group-hover:border-[#d2ac67] group-hover:text-white duration-500 border text-[40px] mb-[15px]'><FaEye /></span>
                            <h2>Our Mission</h2>
                            <p className='mb-0'>The mission of Angan homes is to offer high-quality flats which would satisfy the varying needs of all the customers. With us, you will not only get a 3 BHK luxurious flat offering high standard of living but also a feeling of belongingness, as we are devoted to creating a healthy community living. The team of Angan homes ensures providing-</p>

                            <ul className="grid sm:grid-cols-2 gap-[10px] sm:gap-[12px] md:gap-[5px] mt-[10px]">
                                <li><IoCheckmarkDoneSharp className="mr-[6px] inline-block" /> Quality Construction
                                </li>
                                <li><IoCheckmarkDoneSharp className="mr-[6px] inline-block" /> Innovative Design
                                </li>
                                <li><IoCheckmarkDoneSharp className="mr-[6px] inline-block" /> Sustainable Living
                                </li>
                                <li><IoCheckmarkDoneSharp className="mr-[6px] inline-block" /> Customer Satisfaction
                                </li>
                                <li><IoCheckmarkDoneSharp className="mr-[6px] inline-block" /> Safety and Security
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus