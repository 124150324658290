import React from "react";
import Layout from "../components/shared/layout";
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const TermsConditions = () => {
  return (
    <Layout>
      <InnerPageBanner title='Terms and Conditions' maxWidth='472px' innerPageBanner={ImageIcons.termAndConditions} />

      <section>
        <div className="container">
          <p>Our terms and conditions outline the rules and regulations regarding the usage of our website. By using our services, you show your agreement to comply and abide by our terms and conditions.</p>

          <ul className="mb-[30px]">
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> The Terms “company” “we” “us” “our” refer to the Angan Homes.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> The terms like “user” “you” “your” refer to the individual or the one who is accessing or using our services.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> “Website” refers to the website of Angan homes.
            </li>
          </ul>

          <h3 className="mb-[10px]">Using Our Website</h3>
          <p>If you use our services, you agree to the following-</p>

          <ul className="mb-[30px]">
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> To enter into our terms and conditions, you should have legal capacity and need to be 18 years old.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> By creating an account, you become responsible for taking care of the confidentiality of the information of your account along with the activities which occur under your account.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> You should not use our website for illegal purposes, which hold the capacity of damaging or disabling the website. Our restricted activities include hacking, transmitting viruses and sending spontaneous messages.
            </li>
          </ul>

          <h3 className="mb-[10px]">Booking and Payments</h3>

          <ul className="mb-[30px]">
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> If you book a property through our website or other services, you automatically  show agreement towards providing accurate and complete  information. Bookings are done according to availability at  Angan homes.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> You need to make payments according to the terms and conditions. At Angan homes, various payment methods are accepted by us and you will be informed about everything during the time of booking.
            </li>
          </ul>

          <h3 className="mb-[10px]">Intellectual Property</h3>
          <p>The whole content on the website such as text, graphics, images and everything, is our property and will be safeguarded with the help of our intellectual property laws. You can not reproduce or distribute any work from the content on our website without our consent.</p>

          <h3 className="mb-[10px]">Governing Law</h3>
          <p>Our terms and conditions are governed in accordance with the law of your state. If any dispute arises under these terms, it would be subject to the jurisdiction of the court located in your city or state.</p>

          <h3 className="mb-[10px]">Changes to Terms and Conditions</h3>
          <p>We hold the right to make changes in the terms and conditions at any time and you will be notified about the same. We encourage you to stay in touch with our website and read our terms and conditions. If you continue using our services, you agree to comply with our terms and conditions.</p>
        </div>
      </section>
    </Layout>
  )
}

export default TermsConditions