import logo from "../../assets/images/logo.png";
import banner1 from "../../assets/images/banner1.jpg";
import footerBg from "../../assets/images/footer_bg.png";
import homes from "../../assets/images/homes.webp";
import infoBg from "../../assets/images/info_bg.webp";
import info from "../../assets/images/info.webp";
import clinet1 from "../../assets/images/clinet1.webp";
import clinet2 from "../../assets/images/clinet2.webp";
import clinet3 from "../../assets/images/client3.webp";
import clinet4 from "../../assets/images/client4.webp";
import blog1 from "../../assets/images/blog/blog1.jpg";
import blog2 from "../../assets/images/blog/blog2.jpg";
import blog3 from "../../assets/images/blog/blog3.jpg";
import blog4 from "../../assets/images/blog/blog4.jpg";
import innerbannerbg from "../../assets/images/innerbanner-bg.webp";
import configurationIcon from "../../assets/images/configuration_icon.webp";
import expectedHandOverIcon from "../../assets/images/expected_hand_over_icon.webp";
import residentialLandIcon from "../../assets/images/residential_land_icon.webp";
import totalFlatsIcon from "../../assets/images/total_flats_icon.webp";
import dottteamicon from "../../assets/images/dottteam-icon.webp";
import teamcirpic from "../../assets/images/team-cirpic.webp";
import specialFeatures from "../../assets/images/special-features.jpg";
import connected from "../../assets/images/connected.webp";
import flatLayout from "../../assets/images/flat-layout.webp";
import customerCare from "../../assets/images/customer-care.webp";
import propertyimg1 from "../../assets/images/1.jpg";
import propertyimg2 from "../../assets/images/2.jpg";
import propertyimg3 from "../../assets/images/3.jpg";
import sitesPlan from "../../assets/images/site_plan.png";
import flatsPlan from "../../assets/images/flats_plan.png";
import gallery1 from "../../assets/images/gallery/1.jpg";
import gallery2 from "../../assets/images/gallery/2.jpg";
import gallery3 from "../../assets/images/gallery/3.jpg";
import gallery4 from "../../assets/images/gallery/4.jpg";
import locationScan from "../../assets/images/location_scan.png";
import values from "../../assets/images/values.png";
import myCloudCam from "../../assets/images/logos/my_cloud_cam.png";
import myEvPoints from "../../assets/images/logos/my_ev.webp";
import myInternet from "../../assets/images/logos/my_internet.webp";
import skypro from "../../assets/images/logos/skypro.webp";
import brandsImg from "../../assets/images/brands_images.png";
import ctaBg from "../../assets/images/cta_bg.png";
import whyChoose from "../../assets/images/why_choose.webp";
import ranjitSingh from "../../assets/images/team/ranjit_singh.png";
import pawanpreet from "../../assets/images/team/pawanpreet.png";
import navjotSingh from "../../assets/images/team/navjot_singh.png";
import dotsShap from "../../assets/images/dots_shap.png";
import flatImg from "../../assets/images/flat_img.png";
import liftImg from "../../assets/images/lift_img.jpg";
import flat1 from "../../assets/images/flat1.jpg";
import privacyPolicy from "../../assets/images/top_banners/privacy_policy.webp";
import refundPolicy from "../../assets/images/top_banners/refund_policy.webp";
import termAndConditions from "../../assets/images/top_banners/term_and_conditions.webp";
import bhk3Flats from "../../assets/images/top_banners/3bhk_flats.webp";
import blogs from "../../assets/images/top_banners/blogs.webp";
import aboutUs from "../../assets/images/top_banners/about_us.webp";
import designAndLayout from "../../assets/images/top_banners/design_and_layout.webp";
import contactUs from "../../assets/images/top_banners/contact_us.webp";
import properties from "../../assets/images/top_banners/properties.webp";

const ImageIcons = {
  logo,
  banner1,
  footerBg,
  homes,
  infoBg,
  info,
  clinet1,
  clinet2,
  clinet3,
  clinet4,
  blog1,
  blog2,
  blog3,
  blog4,
  innerbannerbg,
  dottteamicon,
  teamcirpic,
  specialFeatures,
  connected,
  flatLayout,
  customerCare,
  propertyimg1,
  propertyimg2,
  propertyimg3,
  flatsPlan,
  sitesPlan,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  locationScan,
  values,
  myCloudCam,
  myEvPoints,
  myInternet,
  skypro,
  brandsImg,
  ctaBg,
  whyChoose,
  ranjitSingh,
  pawanpreet,
  navjotSingh,
  dotsShap,
  flatImg,
  liftImg,
  flat1,
  privacyPolicy,
  refundPolicy,
  termAndConditions,
  bhk3Flats,
  blogs,
  aboutUs,
  designAndLayout,
  contactUs,
  properties,
  configurationIcon,
  expectedHandOverIcon,
  residentialLandIcon,
  totalFlatsIcon,

};

export default ImageIcons;