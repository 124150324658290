import React from 'react';
import Layout from '../components/shared/layout';
import About from '../components/about/aboutus';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Ourteam from '../components/about/ourteam';
import { MdOutlineGppGood } from "react-icons/md";
import { FaRegHandshake } from "react-icons/fa6";
import { TbBulb } from "react-icons/tb";
import { FaSeedling } from "react-icons/fa";
import OurBrands from '../components/about/ourBrands';
import { Link } from 'react-router-dom';
import { windowScroll } from '../components/utils/windowScroll';

const Aboutus = () => {
  return (
    <Layout>
      <InnerPageBanner title='About Us' maxWidth='472px' innerPageBanner={ImageIcons.aboutUs} />
      <About />
      <section className='bg-[#f5f8fd]'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-[50px] gap-[40px] items-center'>
            <div>
              <h2>Our Values</h2>
              <p>At Angan homes, we feel that our values are the foundation of whatever we do. Our values guide us to shape our culture and be committed to our customers. Our values include</p>

              <div className='mt-[25px] flex justify-center group'>
                <span className='min-w-[50px]'>
                  <MdOutlineGppGood className='text-[#d2ac67] text-[40px]' />
                </span>

                <div className='pl-[15px] w-[calc(100%_-_50px)]'>
                  <h5 className='font-bold mb-[5px]'>Quality</h5>
                  <p className='mb-0'>We are committed to provide and maintain the top-notch quality in every aspect be it construction, material, design or customer services.</p>
                </div>
              </div>
              <div className='mt-[20px] flex justify-center group'>
                <span className='min-w-[40px]'>
                  <FaRegHandshake className='text-[#d2ac67] text-[38px]' />
                </span>

                <div className='pl-[15px] w-[calc(100%_-_50px)]'>
                  <h5 className='font-bold mb-[5px]'>Integrity</h5>
                  <p className='mb-0'>We ensure honesty and transparency in our business. Trust and reliability are our core values when it comes to our clients and partners.</p>
                </div>
              </div>
              <div className='mt-[20px] flex justify-center group'>
                <span className='min-w-[50px]'>
                  <TbBulb className='text-[#d2ac67] text-[40px]' />
                </span>

                <div className='pl-[15px] w-[calc(100%_-_50px)]'>
                  <h5 className='font-bold mb-[5px]'>Innovation</h5>
                  <p className='mb-0'>Angan homes continuously look towards innovation to improve our services.</p>
                </div>
              </div>
              <div className='mt-[20px] flex justify-center group'>
                <span className='min-w-[50px]'>
                  <FaSeedling className='text-[#d2ac67] text-[40px]' />
                </span>

                <div className='pl-[15px] w-[calc(100%_-_50px)]'>
                  <h5 className='font-bold mb-[5px]'>Sustainability</h5>
                  <p className='mb-0'>Our team ensures sustainable living and reduces carbon-footprint.</p>
                </div>
              </div>
            </div>

            <div>
              <img src={ImageIcons.values} alt='' />
            </div>
          </div>
        </div>
      </section>

      <OurBrands />

      <section className="relative max-lg:py-[50px] text-white after:content-[''] after:absolute after:-z-10 after:left-0 after:right-0 after:top-0 after:w-full after:h-full after:bg-[#000] bg-no-repeat bg-cover" style={{ backgroundImage: `url(${ImageIcons.ctaBg})` }}>
        <div className='container'>
          <div className='flex gap-[30px] max-md:flex-col max-md:text-center items-center justify-between'>
            <div>
              <h2>Get started with Space</h2>
              <p className='mb-0'>Space gives you everything you need to manage business, build great stuff, and reach your goals.</p>
            </div>

            <Link onClick={windowScroll} className='lg:min-w-[190px] min-w-[170px] text-center px-[30px] text-[18px] lg:py-[15px] py-[12px] font-medium text-[#fff] border border-[#fff] rounded-[6px] hover:text-[#000] hover:bg-[#fff] ease inline-block' to='/contact-us'>Contact Us</Link>
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-[50px] gap-[40px] items-center'>
            <div>
              <h2>Why Choose Angan Homes?</h2>
              <p>We give priority to your security and comfort, so we provide you with an advanced surveillance system. Our independent floors are equipped with elevators which have Automatic Rescue Devices (ARD). Along with this, we also provide servo stabilisers for the purpose of safety and efficiency. We have ensured providing underground wiring, so that you can enjoy a clutter free and a safe living space.</p>

              <h3>Amenities</h3>
              <p>Angan homes real estate consultants’ 3 BHK luxurious flats are located near a 2-acre government park, which provides you with serene and green surroundings. We ensure round the clock manned security, so that you can have peace of mind. We provide many other amenities such as Wi-Fi hotspots, EV charging infrastructure, CCTV Monitoring, covered parking, broadband services, and IPTV services also.</p>

              <p className='mb-0'>Apart from this, we also provide our customers with special features such as fully furnished 3 BHK luxurious flats, water storage tank, RCC bands for structural integrity, pre-plaster chicken wire mesh, concrete curing, floor waterproofing and much more.</p>
            </div>

            <div>
              <img src={ImageIcons.whyChoose} alt='' />
            </div>
          </div>
        </div>
      </section>

      <Ourteam />
    </Layout>
  )
}

export default Aboutus