import React from 'react';
import Layout from '../components/shared/layout';
import Hero from '../components/home/hero';
import About from '../components/home/about';
import Info from '../components/home/info';
import Featuredproperties from '../components/home/featuredproperties';
import Blog from '../components/home/blogs';
import Connected from '../components/home/connected';
import QuickFacts from '../components/home/quickFacts';
import FlatsPlans from '../components/home/flatsPlans';
import Offering from '../components/home/offering';
import Gallery from '../components/home/gallery';
import Map from '../components/home/map';
// import Ourservice from '../components/home/ourservices';
// import Awesomefeatures from '../components/home/awesomefeatures';
// import Testimonial from '../components/shared/testimonials';
// import SpecialFeatures from '../components/home/specialFeatures';
// import Welcome from '../components/home/welcome';

const Home = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <Featuredproperties />
      <Connected />
      <Info />
      <QuickFacts />
      <FlatsPlans />
      <Offering />
      <Gallery />
      <Blog />
      <Map />

      {/* <Ourservice /> */}
      {/* <SpecialFeatures />
      <Awesomefeatures />
      <Testimonial /> */}


    </Layout>
  )
}

export default Home
