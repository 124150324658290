import React from 'react'

const Map = () => {
    return (
        <section className="p-0">
            <iframe
                title='map'
                width="100%"
                className='min-h-[100%] h-[350px] md:h-[420px] bg-[#e8eaed]'
                height=""
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.180033914094!2d76.69176177607471!3d30.713338786568798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef99cc17fe25%3A0x6a593a19f7208f9b!2sAbacus%20Cloud!5e0!3m2!1sen!2sus!4v1705467957769!5m2!1sen!2sus"
            ></iframe >
        </section >
    )
}
export default Map