import React from 'react';
import ImageIcons from '../imageComponent/ImageIcons';

const FlatsPlans = () => {
    return (
        <section className='pt-0'>
            <div className='container'>
                <h2 className='text-center mb-[30px]'>Site And Flats Plans</h2>
                <div className='grid md:grid-cols-2 lg:gap-[30px] gap-[20px]'>
                    <div>
                        <img src={ImageIcons.sitesPlan} alt='' className='w-full' />
                    </div>
                    <div>
                        <img src={ImageIcons.flatsPlan} alt='' className='w-full' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FlatsPlans