import React from 'react'
import { FaBed, FaBath } from "react-icons/fa";
import { RiShapeFill } from "react-icons/ri";
import { IoLocationSharp } from "react-icons/io5";
import ImageIcons from '../imageComponent/ImageIcons';
import { Link } from 'react-router-dom';
import { windowScroll } from '../utils/windowScroll';

const Featuredproperties = () => {
    return (
        <section>
            <div className='container'>
                <div>
                    <h2 className='text-center mb-[30px]'>Featured Properties</h2>
                </div>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 max-lg:gap-[20px] lg:gap-[30px]'>
                    {propertiesData.map((item, i) => (
                        <Link to={item.handle} key={i} onClick={windowScroll} className='bg-[#fff] shadow-[0px_0px_25px_0px_rgba(0,0,0,0.15)] group overflow-hidden'>
                            <div className='feature-prd-pic relative overflow-hidden h-[280px]'>
                                <img src={item.image} alt='' className='group-hover:scale-[1.1] duration-500 w-full h-full object-center object-cover' />
                                <div className='bg-[#359dd0] text-[#fff] absolute top-[10px] left-[10px] px-[10px] py-[5px]'>
                                    <span className='text-[13px]'>{item.sale}</span>
                                </div>
                            </div>
                            <div className='feature-prd-info p-4 mt-10px'>
                                <h3 className='text-[22px] mb-[10px] group-hover:text-[#359dd0] duration-500'>{item.title}</h3>
                                <p className='text-[15px]'>{item.text}</p>
                                <div className='flex flex-wrap gap-[15px] mb-[15px] border-b-[1px] pb-[15px]'>
                                    {item.bedroomList.map((item, i) => (
                                        <div className=''>
                                            <div>
                                                <span className='flex gap-[10px] items-center'> <span className='text-[#b0b0b0] text-[22px]'>{item.icon}</span> {item.size}</span>
                                            </div>
                                            <p className='text-[15px] text-[#b0b0b0] mb-[0px]'>{item.title}</p>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <p className='flex text-[15px] gap-[5px] mb-[0px]'><IoLocationSharp className='text-[#b0b0b0]' size={22} /> {item.address}</p>
                                </div>
                            </div>
                        </Link>

                    ))}
                </div>
            </div>
        </section>
    )
}

const propertiesData = [
    {
        title: "New Apartment Nice View",
        handle: "/properties",
        text: "Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood",
        sale: "FOR SALE",
        image: ImageIcons.propertyimg1,
        address: "High Ground Road, Chandigarh-Patiala Highway, Zirakpur",
        bedroomList: [
            {
                title: "Bedrooms",
                size: "3",
                icon: <FaBed />,
            },
            {
                title: "Bedrooms",
                size: "3",
                icon: <FaBath />,
            },
            {
                title: "square Ft",
                size: "1230",
                icon: <RiShapeFill />,
            },
        ]
    },
    {
        title: "Comfortable Apartment",
        handle: "/properties",
        text: "Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood",
        sale: "FOR SALE",
        image: ImageIcons.propertyimg2,
        address: "High Ground Road, Chandigarh-Patiala Highway, Zirakpur",
        bedroomList: [
            {
                title: "Bedrooms",
                size: "3",
                icon: <FaBed />,
            },
            {
                title: "Bedrooms",
                size: "3",
                icon: <FaBath />,
            },
            {
                title: "square Ft",
                size: "1230",
                icon: <RiShapeFill />,
            },
        ]
    },
    {
        title: "Beautiful Flat in Manhattan",
        handle: "/properties",
        text: "Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood",
        sale: "FOR SALE",
        image: ImageIcons.propertyimg3,
        address: "High Ground Road, Chandigarh-Patiala Highway, Zirakpur",
        bedroomList: [
            {
                title: "Bedrooms",
                size: "3",
                icon: <FaBed />,
            },
            {
                title: "Bedrooms",
                size: "3",
                icon: <FaBath />,
            },
            {
                title: "square Ft",
                size: "1230",
                icon: <RiShapeFill />,
            },
        ]
    },

]

export default Featuredproperties