import React from "react";
import Layout from "../components/shared/layout";
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import { GiWaterTank } from "react-icons/gi";
import { SiInstructure } from "react-icons/si";
import { SiTraefikmesh } from "react-icons/si";
import { GiConcreteBag } from "react-icons/gi";
import { GiFloorHatch } from "react-icons/gi";
import { BiSolidBuildingHouse } from "react-icons/bi";
import { Link } from "react-router-dom";
import { IoMdWifi } from "react-icons/io";
import { FaChargingStation } from "react-icons/fa6";
import { TbDeviceCctv } from "react-icons/tb";
import { FaParking } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";
import { windowScroll } from "../components/utils/windowScroll";

const Flats = () => {
  return (
    <Layout>
      <InnerPageBanner title='3 BHK Flats' innerPageBanner={ImageIcons.bhk3Flats} />

      <section className="bg-[#f5f8fd]">
        <div className='container'>
          <div className="grid md:grid-cols-2 items-center lg:gap-[50px] gap-[40px]">
            <div>
              <h2 className=''>3 BHK Flats</h2>
              <p className="mb-0 xl:text-[20px]">At Angan homes, we provide lots of facilities to our customers, which offer them an outstanding living experience. Our 3 BHK independent floors have been designed by focusing on high-quality materials, so that we can offer comfort, style, and convenience, thereby making our apartments an ideal choice for the individuals seeking modern living in countryside ambience.</p>
            </div>
            <div className="about-img-wrap">


              <div className="relative pb-[150px] z-10">

                <div className="absolute z-[1] sm:left-[25px] left-0 bottom-0 text-white lg:text-[20px] text-[16px] lg:w-[150px] w-[120px] lg:h-[150px] h-[120px] flex items-center justify-center text-center px-5 py-2.5 rounded-full bg-[linear-gradient(157deg,#723f00_0%,#8a5b0e_100%)] animate-[animate-bounceTop_1500ms_infinite_ease-in-out] ">3 BHK independent Floors</div>
                <div className="absolute z-[-1] right-0 top-[60px] rounded-full animate-[animate-bounceTop_2000ms_infinite_ease-in-out]">
                  <img src={ImageIcons.dotsShap} alt="" className="" />
                </div>
                <img src={ImageIcons.banner1} alt="" className="w-[85%] xl:h-[400px] lg:h-[300px] h-[250px] object-cover mr-auto rounded-[5px]" />
                <img src={ImageIcons.flatImg} alt="" className="absolute max-w-[70%] xl:h-[310px] h-[270px] object-cover rounded-[5px] right-0 bottom-0" />

              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <div className="text-center mb-[40px] mx-auto max-w-[1050px]">
            <h2>Special Features</h2>
            <p>We give deep attention to details and always give preference to top-notch materials. Our commitment towards quality ensures that our 3 BHK luxurious apartments meet top standards of excellence and durability. Below given are the details of our special features</p>
          </div>

          <div className='grid md:grid-cols-3 sm:grid-cols-2 lg:gap-[20px] gap-[15px] mt-[30px]'>
            {specialFeaturesData.map((item, i) => (
              <div key={i} className='xl:p-[30px] p-[20px] text-center duration-500 group shadow-[0px_0px_5px_0px_#362d1c] hover:shadow-[0px_0px_5px_1px_#cda968a1] bg-[#000] text-[#fff]'>
                <div className='sm:mb-[5px]'>
                  <span className='lg:text-[50px] md:w-[90px]  w-[70px] md:h-[90px] h-[70px] rounded-full border flex items-center justify-center border-[#362d1c] sm:text-[35px] text-[30px] text-[#d2ae6d] mx-auto mb-[20px] group-hover:bg-[#723f00] group-hover:border-[#723f00] group-hover:text-white duration-700 group-hover:scale-[1.04]'>{item.icon}</span>
                </div>

                <div>
                  <h6 className='mb-[15px] group-hover:text-white'>{item.title}</h6>
                  <p className="mb-0">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="relative max-lg:py-[50px] text-white after:content-[''] after:absolute after:-z-10 after:left-0 after:right-0 after:top-0 after:w-full after:h-full after:bg-[linear-gradient(157deg,#723f00_0%,#8a5b0e_100%)] bg-no-repeat bg-cover" style={{ backgroundImage: `url(${ImageIcons.ctaBg})` }}>
        <div className='container'>
          <div className='flex gap-[30px] max-md:flex-col max-md:text-center items-center justify-between'>
            <div>
              <h2>Get started with Space</h2>
              <p className='mb-0'>Space gives you everything you need to manage business, build great stuff, and reach your goals.</p>
            </div>

            <Link onClick={windowScroll} className='lg:min-w-[190px] min-w-[170px] text-center px-[30px] text-[18px] lg:py-[15px] py-[12px] font-medium text-[#fff] border border-[#fff] rounded-[6px] hover:text-[#723f00] hover:bg-[#fff] ease inline-block' to='/contact-us'>Contact Us</Link>
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <h2 className='text-center'>Our Other Amenities</h2>
          <div className='grid md:grid-cols-3 sm:grid-cols-2 lg:gap-[20px] gap-[10px] mt-[30px]'>
            {otherAmenitiesData.map((item, i) => (
              <div key={i} className='shadow-[0px_0px_15px_0px_rgba(0,0,0,0.15)] xl:p-[30px] p-[20px] text-center rounded-t-[20px] hover:rounded-t-none duration-500 hover:rounded-b-[20px] bg-[#000] hover:bg-[linear-gradient(157deg,#723f00_0%,#8a5b0e_100%)] group'>
                <div className='mb-[5px]'>
                  <span className='lg:text-[50px] text-[35px] text-[#d2ae6d] inline-block group-hover:text-white duration-700 group-hover:scale-110'>{item.icon}</span>
                </div>

                <div>
                  <h6 className='mb-[12px] text-white'>{item.title}</h6>
                  <p className="mb-0 text-white">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>



      <section className="pt-0">
        <div className="container">
          <p className="mb-0"><i>At Angan homes, we not only offer a place to live but a thriving community. We are devoted to providing quality and security, making our 3 BHK independent floors a perfect choice for your next home. Join our community of thousands of satisfied customers and enjoy the best facilities of the time.
          </i></p>
        </div>
      </section>

    </Layout>
  )
}

const otherAmenitiesData = [
  {
    title: "Wi-Fi Hotspots",
    text: 'We provide our customers with the facility of Wi-Fi hotspots in our flats, allowing you to stay connected with our high-speed internet.',
    icon: <IoMdWifi />,
  },
  {
    title: "EV Charging Infrastructure",
    text: 'We also provide you with the dedicated EV charging facilities, so that you can charge your vehicles in a convenient way.',
    icon: <FaChargingStation />,
  },
  {
    title: "CCTV Monitoring",
    text: 'You will also be provided with the facility of cloud cam, ensuring round the clock surveillance.',
    icon: <TbDeviceCctv />,
  },
  {
    title: "3 BHK Flats with Covered Parking",
    text: 'Customers will also get the facility of parking along with an additional store room for your convenience.',
    icon: <FaParking />,
  },
  {
    title: "FTTH Broadband and IPTV Services",
    text: 'We allow you to enjoy entertainment  with our IPTV services, which are provided over the internet and we also provide you the chance to get high-speed  Fibre-to-the-home broadband connectivity.',
    icon: <FaWifi />,
  }
]

const specialFeaturesData = [
  {
    icon: <BiSolidBuildingHouse />,
    title: "Fully Furnished 3 BHK luxurious flats",
    text: "We provide ready to move independent floors with complete furnishing.",
  },
  {
    icon: <GiWaterTank />,
    title: "Overhead Water Storage Tanks",
    text: "We ensure providing our customers with reliable water supply.",
  },
  {
    icon: <SiInstructure />,
    title: "RCC Bands for Structural Integrity",
    text: "Angan homes also provides reinforced concrete cement bands after a period of certain time for the purpose of enhancing the strength of the building.",
  },
  {
    icon: <SiTraefikmesh />,
    title: "Pre-Plaster Chicken Wire Mesh",
    text: "It is applied on the walls before plastering, as it helps prevent cracks.",
  },
  {
    icon: <GiConcreteBag />,
    title: "Concrete Curing",
    text: "Clean water is being used for curing concrete, we do not compromise when it comes to quality.",
  },
  {
    icon: <GiFloorHatch />,
    title: "Floor Waterproofing",
    text: "In our 3 BHK luxury house, floor waterproofing is done properly to ensure durability by eliminating the moisture.",
  },

]

export default Flats