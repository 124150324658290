import React from 'react';
import ImageIcons from '../imageComponent/ImageIcons';
import { Link } from 'react-router-dom';

const OurBrands = () => {
    return (
        <section>
            <div className="container">
                <div className="grid lg:grid-cols-2 gap-[50px] justify-between items-center">
                    <div className="">
                        <h2>Our Group Brands</h2>
                        <p>We not only provide real estate services but have other brands also which are mentioned below-</p>
                        <img src={ImageIcons.brandsImg} alt='' />
                    </div>

                    <div className="flex flex-wrap -mx-3">
                        <Link to='https://www.myevpoint.in/' target='_blank' className="w-full sm:w-1/2 px-3 mb-6 mt-[25px]">
                            <div className="bg-[#fff] hover:shadow-[0_0_35px_rgba(140,152,164,0.25)] border border-t-4 border-[#e3e6f0] hover:border-[#097034] duration-500 p-[30px] rounded-lg mb-3 ">
                                <img className='h-[50px] mb-[20px]' src={ImageIcons.myEvPoints} alt='' />
                                <h5 className="font-bold text-gray-900 mb-[10px]">MyEV Point</h5>
                                <p className="mb-0">Our commitment towards sustainability has encouraged us to promote the usage of sustainable transportation by providing both AC and DC EV charging station services.</p>
                            </div>
                        </Link>

                        <Link to='https://www.myinternet.services/' target='_blank' className="w-full sm:w-1/2 px-3 mb-6">
                            <div className="bg-[#fff] hover:shadow-[0_0_35px_rgba(140,152,164,0.25)] border border-t-4 border-[#e3e6f0] hover:border-[#f38432] duration-500 p-[30px] rounded-lg mb-3 ">
                                <img className='h-[50px] mb-[20px]' src={ImageIcons.myInternet} alt='' />
                                <h5 className="font-bold text-gray-900 mb-[10px]">My Internet</h5>
                                <p className="mb-0">In the name of My Internet, we provide our customers with reliable, secure and high-speed internet services. Our services include 4G, 5G, broadband services, Wi-Fi services, and much more.</p>
                            </div>
                        </Link>

                        <Link to='https://www.mycloudcam.in/' target='_blank' className="w-full sm:w-1/2 px-3 mb-6">
                            <div className="bg-[#fff] hover:shadow-[0_0_35px_rgba(140,152,164,0.25)] border border-t-4 border-[#e3e6f0] hover:border-[#ef7f1a] duration-500 p-[30px] rounded-lg mb-3 ">
                                <img className='h-[50px] mb-[20px]' src={ImageIcons.myCloudCam} alt='' />
                                <h5 className="font-bold text-gray-900 mb-[10px]">My Cloud Cam</h5>
                                <p className="mb-0">With our cloud cam, we offer advanced surveillance systems along with the facility of storing the footage on the cloud.</p>
                            </div>
                        </Link>

                        <Link to='https://www.skypro.co.in/' target='_blank' className="w-full sm:w-1/2 px-3 mb-6">
                            <div className="bg-[#fff] hover:shadow-[0_0_35px_rgba(140,152,164,0.25)] border border-t-4 border-[#e3e6f0] hover:border-[#061e43] duration-500 p-[30px] rounded-lg mb-3 ">
                                <img className='h-[50px] mb-[20px]' src={ImageIcons.skypro} alt='' />
                                <h5 className="font-bold text-gray-900 mb-[10px]">Skypro IPTV</h5>
                                <p className="mb-0">We also offer internet protocol television services, where we offer a wide range of channels and other programming, allowing you to immerse yourself into the realm of entertainment.</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurBrands