import React from 'react';
import ImageIcons from '../imageComponent/ImageIcons';

const Ourservice = () => {
    return (
        <section className='bg-[#f5f8fd]'>
            <div className='container relative z-10'>
                <div>
                    <h2 className='text-center mb-[30px]'>Our Leadership Team</h2>
                </div>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3 max-lg:gap-[20px] lg:gap-[30px]'>
                    <div className='text-center relative overflow-hidden rounded-lg group duration-700'>
                        <div className="xl:h-[510px] lg:h-[500px] sm:h-[400px] overflow-hidden relative after:duration-1000 after:absolute after:content-[''] after:left-0 after:bottom-0 after:w-full after:h-0 after:bg-[linear-gradient(0deg,rgb(176_207_221)_0%,rgb(26_180_255)_100%)] group-hover:after:!h-[100%] after:opacity-50">
                            <img src={ImageIcons.ranjitSingh} alt='Ranjit Singh Sandhu' className='w-full object-cover h-full' />
                        </div>
                        <div className='absolute bottom-5 left-0 w-full text-center'>
                            <div className='relative mx-5 overflow-hidden rounded-lg bg-white py-4 px-3'>
                                <h5 className="text-[20px] mb-0">Ranjit Singh Sandhu</h5>
                                <div className='duration-700 invisible group-hover:visible group-hover:overflow-visible overflow-hidden h-0 group-hover:h-auto w-[100%] group-hover:mt-[10px]'>
                                    <p className="text-[15px] mb-0 leading-[20px] group-hover:leading-[22px] duration-500">My experience in the real estate industry has helped me know about the ongoing trends in the market. With my passion for innovation, I am committed to delivering high-quality and sustainable living spaces.</p>
                                </div>
                                <div>
                                    <span className='absolute left-0 bottom-0'><img src={ImageIcons.teamcirpic} alt='teamcirpic' /> </span>
                                    <span className='absolute top-0 right-0'><img src={ImageIcons.dottteamicon} alt='dottteamicon' /> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center relative overflow-hidden rounded-lg group'>
                        <div className="xl:h-[510px] lg:h-[500px] sm:h-[400px] overflow-hidden relative after:duration-1000 after:absolute after:content-[''] after:left-0 after:bottom-0 after:w-full after:h-0 after:bg-[linear-gradient(0deg,rgb(176_207_221)_0%,rgb(26_180_255)_100%)] group-hover:after:!h-[100%] after:opacity-50">
                            <img src={ImageIcons.navjotSingh} alt='Navjot Singh Sandhu' className='w-full object-cover h-full' />
                        </div>
                        <div className='absolute bottom-5 left-0 w-full text-center'>
                            <div className='relative mx-5 overflow-hidden rounded-lg bg-white py-4 px-3'>
                                <h5 className="text-[20px] mb-0">Navjot Singh Sandhu</h5>

                                <div className='duration-700 invisible group-hover:visible group-hover:overflow-visible overflow-hidden h-0 group-hover:h-auto w-[100%] group-hover:mt-[10px]'>
                                    <p className="text-[15px] mb-0 leading-[20px] group-hover:leading-[22px] duration-500">Being the director of operations, I have always wanted a seamless execution of our projects. I am committed to ensure that each of our projects meets our strict quality standards along with being delivered on time. Fostering the culture of safety and sustainability is my top priority.</p>
                                </div>
                                <div>
                                    <span className='absolute left-0 bottom-0'><img src={ImageIcons.teamcirpic} alt='teamcirpic' /> </span>
                                    <span className='absolute top-0 right-0'><img src={ImageIcons.dottteamicon} alt='dottteamicon' /> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center relative overflow-hidden rounded-lg group">
                        <div className="xl:h-[510px] lg:h-[500px] sm:h-[400px] overflow-hidden relative after:duration-1000 after:absolute after:content-[''] after:left-0 after:bottom-0 after:w-full after:h-0 after:bg-[linear-gradient(0deg,rgb(176_207_221)_0%,rgb(26_180_255)_100%)] group-hover:after:!h-[100%] after:opacity-50">
                            <img src={ImageIcons.pawanpreet} alt='pawanpreet' className='w-full object-cover h-full' />
                        </div>
                        <div className='absolute bottom-5 left-0 w-full text-center'>
                            <div className='relative z-20 mx-5 overflow-hidden rounded-lg bg-white py-4 px-3'>
                                <h5 className="text-[20px] mb-0">Pawanpreet Dhaliwal</h5>
                                <div className='duration-700 invisible group-hover:visible group-hover:overflow-visible overflow-hidden h-0 group-hover:h-auto w-[100%] group-hover:mt-[10px]'>
                                    <p className="text-[15px] mb-0 leading-[20px] group-hover:leading-[22px] duration-500">I am focused towards bringing a blend of creativity and strategic thinking within my team. My experience in real estate has helped me understand the needs of the customers along with market trends. I am always devoted to align our marketing efforts with expectations of the buyers, so that we can form a strong bond and gain the trust of our customers.</p>
                                </div>
                                <div>
                                    <span className='absolute left-0 bottom-0'><img src={ImageIcons.teamcirpic} alt='teamcirpic' /> </span>
                                    <span className='absolute top-0 right-0'><img src={ImageIcons.dottteamicon} alt='dottteamicon' /> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Ourservice