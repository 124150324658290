import React from "react";
import Layout from "../components/shared/layout";
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <InnerPageBanner title='Privacy Policy' maxWidth='472px' innerPageBanner={ImageIcons.privacyPolicy} />

      <section>
        <div className="container">
          <p>Angan homes is a real estate company, Which is committed to safeguarding your personal information. Our privacy policy provides you with the information on how we collect, use and protect your information when you pay a visit to our website. Using our services shows your agreement to our privacy policy.</p>

          <h3 className="mb-[10px]">Information We Collect</h3>

          <h5 className="font-bold mb-[5px]">Personal Information</h5>
          <p>When you visit our website, your personal information, which you provide us voluntarily, is collected by us. This information includes-</p>

          <ul className="mb-[30px]">
            <li className="mb-[8px]"><strong>Name: </strong>So that we can address you properly.</li>
            <li className="mb-[8px]"><strong>Email addres: </strong>It helps us communicate with you, as we need to send you updates. It also helps us to respond to your queries.</li>
            <li className="mb-[8px]"><strong>Phone number: </strong>Your phone number helps us to contact you directly.</li>
          </ul>

          <h3 className="mb-[10px]">Automatically Collected Information</h3>
          <p>Some of your information is being collected automatically when you visit our website-</p>

          <ul className="mb-[30px]">
            <li className="mb-[8px]"><strong>IP address: </strong>With this, we get to know about your location and enhance the security.</li>
            <li className="mb-[8px]"><strong>Browser type: </strong>It helps us optimise the website for distinct browsers.</li>
            <li className="mb-[8px]"><strong>Operating system: </strong>Compatibility with different devices is being ensured.</li>
            <li className="mb-[8px]"><strong>Referring URLs: </strong>It helps us understand how you came to your website.</li>
            <li className="mb-[8px]"><strong>Pages Viewed: </strong>It gives us insight into the pages you like the most.</li>
            <li className="mb-[8px]"><strong>Date and time of visit: </strong>With this, we become able to track and manage the peak usage times</li>
          </ul>

          <h3 className="mb-[10px]">How We Use Your Information</h3>
          <p>The information collected is used in various ways which are mentioned below</p>

          <ul className="mb-[30px]">
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> Your information helps us to provide and maintain our website, so that we can make sure that our website is working efficiently and correctly.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> It also proves to be helpful for improving and personalising our services because with this, we become able to customise our services according to your preferences.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> With your information, we send you updates and answer your queries.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> We also get to know about the trends by monitoring them and it allows us to improve our website and services.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> According to your feedback, we work on innovations.
            </li>
          </ul>

          <h3 className="mb-[10px]">Information Sharing and Disclosure</h3>
          <p>Your information is not being sold or rented to third parties. But it can be shared with</p>

          <ul className="mb-[30px]">
            <li className="mb-[8px]"><strong>Service providers: </strong>Your information is being shared with the third-party service providers. These providers look after payment processing, data analysis and other works related to marketing assistance.</li>
            <li className="mb-[8px]"><strong>Business Transfers: </strong>In case of merger or acquisition, we may provide your information to the responsible ones.</li>
            <li className="mb-[8px]"><strong>Legal Requirements: </strong>Your information may be disclosed by us if it is the requirement of the law, as we need to abide by law.</li>
          </ul>

          <h3 className="mb-[10px]">Data Security</h3>

          <p>In order to safeguard your personal information from any of the illegal accesses, we ensure implementing technical and organisational measures. In these measures, communication channels are encrypted, data storage is kept secure and access controls are also implemented. Although all the security measures are being used by us, not a single way of transmission is properly secure over the internet, so security can not be guaranteed by us.</p>


          <h3 className="mb-[10px]">Your Data Protection Rights</h3>
          <p>You are provided with the following data protection rights according to your location.</p>

          <ul className="mb-[30px]">
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> You can request access to the copies of your personal information,
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> You can also ask for correction or completion of inaccurate or incomplete information respectively.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> You are also provided with the right to delete your personal information.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> You can also ask to transfer your information to any other organisation or to you.
            </li>
          </ul>

          <h3 className="mb-[10px]">Third-Party Links</h3>
          <p>You may find links to the third party websites on our website, however we shall not be held responsible for any privacy practices on them. So, you should always read their privacy policy when you visit their site.</p>

          <h3 className="mb-[10px]">Changes to the Policy</h3>
          <p>Our privacy policy can be updated any time and you will be notified about the same. If you continue using our services, you are considered in agreement to our services.</p>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy