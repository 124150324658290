import React from 'react'
import { MdOutlineLocalAirport } from "react-icons/md";
import { TbBus } from "react-icons/tb";
import { IoSchoolSharp } from "react-icons/io5";
import { FaHospital } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { SiMcdonalds } from "react-icons/si";
import { FaUmbrellaBeach } from "react-icons/fa6";
import { ImSpoonKnife } from "react-icons/im";
import { IoShareSocial } from "react-icons/io5";
import { GiShoppingBag } from "react-icons/gi";
import { BsBuildingsFill } from "react-icons/bs";
import { BiSolidPurchaseTag } from "react-icons/bi";

const Connected = () => {
    return (
        <section className='pt-0'>
            <div className='container'>
                <h2 className='text-center'>Project Amenities</h2>
                <div className='grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 lg:gap-[20px] gap-[10px] mt-[30px]'>
                    {connectedData.map((item, i) => (
                        <div key={i} className='shadow-[0px_0px_15px_0px_rgba(0,0,0,0.15)] xl:p-[30px] p-[20px] text-center rounded-t-[20px] hover:rounded-t-none duration-500 hover:rounded-b-[20px] bg-[#000] hover:bg-[linear-gradient(157deg,#723f00_0%,#8a5b0e_100%)] group'>
                            <div className='sm:mb-[5px]'>
                                <span className='lg:text-[50px] sm:text-[35px] text-[30px] text-[#d2ae6d] inline-block group-hover:text-white duration-700 group-hover:scale-110'>{item.icon}</span>
                            </div>

                            <div>
                                <h6 className='mb-0 text-white max-xl:text-[18px] max-lg:text-[17px] max-sm:text-[16px]'>{item.title}</h6>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

const connectedData = [
    {
        title: "International Airport - 10 Mins",
        icon: <MdOutlineLocalAirport />,
    },
    {
        title: "Zirakpur Bus Stand - 5 Mins",
        icon: <TbBus />,
    },
    {
        title: "Schools - 3 Mins",
        icon: <IoSchoolSharp />,
    },
    {
        title: "Hospitals - 5 Mins",
        icon: <FaHospital />,
    },
    {
        title: "Cosmo Mall - 8 Mins",
        icon: <FaShoppingCart />,
    },
    {
        title: "McDonald's - 8 Mins",
        icon: <SiMcdonalds />,
    },
    {
        title: "Opposite Apple Resort",
        icon: <FaUmbrellaBeach />,
    },
    {
        title: "Gopal Sweets - 1 Mins",
        icon: <ImSpoonKnife />,
    },
    {
        title: "HLP Social Square - 2 Mins",
        icon: <IoShareSocial />,
    },
    {
        title: "Elante Mall - 15 Mins",
        icon: <GiShoppingBag />,
    },
    {
        title: "JLPL - 20 Mins",
        icon: <BsBuildingsFill />,
    },
    {
        title: "D-Mart - 8 Mins",
        icon: <BiSolidPurchaseTag />,
    },
]
export default Connected