import React from 'react';
import Layout from '../components/shared/layout';
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';

const DesignAndLayout = () => {
  return (
    <Layout>
      <InnerPageBanner title='Design And Layout' maxWidth='472px' innerPageBanner={ImageIcons.designAndLayout} />

      <section>
        <div className='container'>
          <div className='grid md:grid-cols-2 lg:gap-[30px] gap-[20px]'>
            <div>
              <img src={ImageIcons.sitesPlan} alt='' className='w-full' />
            </div>
            <div>
              <img src={ImageIcons.flatsPlan} alt='' className='w-full' />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DesignAndLayout