import React from 'react';
import ImageIcons from '../imageComponent/ImageIcons';

const QuickFacts = () => {
    return (
        <section className=''>
            <div className='container'>
                <h2 className='text-center mb-[30px]'>Quick Facts</h2>
                <div className='grid grid-cols-2 md:grid-cols-4 lg:gap-[30px] md:gap-[20px] gap-[10px]'>
                    {quickFactsData.map((item) => (
                        <div className='bg-[#fff] shadow-[0px_0px_15px_0px_rgba(0,0,0,0.15)] group overflow-hidden'>
                            <div className='text-center px-[10px] md:py-[40px] py-[20px]'>
                                <img src={item.icon} alt='' className='group-hover:scale-[1.1] duration-500 w-full md:max-w-[150px] sm:max-w-[130px] max-w-[100px] mx-auto' />
                                <h4 className='md:text-[20px] sm:text-[18px] text-[15px] font-bold mb-0'>{item.title}</h4>
                                <h5 className='md:text-[20px] sm:text-[18px] text-[15px] font-bold mb-0'>{item.text}</h5>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

const quickFactsData = [
    {
        title: "Residential Land",
        text: "7 Acre",
        icon: ImageIcons.residentialLandIcon,
    },
    {
        title: "Total Flats",
        text: "383",
        icon: ImageIcons.totalFlatsIcon,
    },
    {
        title: "Configuration",
        text: "S+13",
        icon: ImageIcons.configurationIcon,
    },
    {
        title: "Expected hand over",
        text: "Feb 2024",
        icon: ImageIcons.expectedHandOverIcon,
    },
]

export default QuickFacts