import React from 'react'

const Offering = () => {
    return (
        <section className='bg-[#f5f8fd]'>
            <div className='container !max-w-[1000px]'>
                <div className='relative p-[28%] w-full overflow-hidden'>
                    <iframe className='cursor-pointer absolute top-0 left-0 w-full h-full object-center object-cover' src="https://www.youtube.com/embed/-c345tCaU8w?si=xr1gb86Bcz_HU6IR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </section>
    )
}
export default Offering