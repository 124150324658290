import React from 'react';
import { Link } from 'react-router-dom';
import { windowScroll } from '../utils/windowScroll';

const Button = ({ title = "", hrefLink = "" }) => {
    return (
        <Link to={hrefLink} onClick={windowScroll} class='group relative inline-flex items-center justify-center overflow-hidden z-10 transition-all duration-500 ease-in-out px-[50px] text-[18px] py-[15px] font-medium text-[#fff] bg-[#359dd0] rounded-[6px] hover:text-[#fff] '>
            {title}
            <span class='absolute bg-[#d2ac67] bottom-0 w-0 right-0 h-full transition-all ease-in-out duration-500 group-hover:w-[105%] -z-[1] group-focus:w-[105%] group-hover:right-auto group-hover:left-0'></span>
        </Link>
    )
}

export default Button;