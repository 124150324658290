import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import Button from '../shared/button';

const About = () => {
    return (
        <section className='bg-[#f5f8fd]'>
            <div className='container'>
                <div className='grid lg:grid-cols-2 gap-[40px] items-center'>
                    <div>
                        <h2>About Us</h2>
                        <p>Welcome to Angan homes,  where luxury meets affordability. We are your trusted partners specialised in providing luxury and affordable apartments in Zirakpur customised to your needs. We allow you to enjoy your home buying journey by providing outstanding services and exceptional guidance. Our apartments are located in prime locations and you can get easy access to all the essential facilities, thereby providing a comfortable living experience. Each of our apartments is equipped with contemporary furnishing, security system along with other necessary amenities such as on-site maintenance and laundry facilities. We are devoted to making your renting and purchasing experience straightforward, as we offer virtual tours along with online applications. You can contact us any time to schedule an appointment with us to get to know about the availability of flats.</p>
                        <Button hrefLink='/about-us' title='Learn more' />
                    </div>

                    <div>
                        <img className='mx-auto' src={ImageIcons.homes} alt='' />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default About