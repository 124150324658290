import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { FiUser } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiArrowRightLine } from "react-icons/ri";
import Slider from "react-slick";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { HiOutlineArrowRight } from "react-icons/hi";
import { windowScroll } from "../utils/windowScroll";
import { GetRequest } from "../../components/utils/request";


const Blog = () => {
    const [allBlogs, setAllBlogs] = useState([]);

    useEffect(() => {
        GetRequest(`${process.env.REACT_APP_URL}/blogs/getBlogs`)
            .then((response) => {
                console.log(response.data)
                const activeBlogs = response.data.blogs.filter(blog => blog.isActive);
                setAllBlogs(activeBlogs);
            }).catch(err => {
                console.log(err);
            });
    }, [])

    const sliderRef = useRef(null);
    let settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <>
            <section className='blog pt-0'>
                <div className='container'>
                    <h2 className='text-center mb-[30px]'>Latest Blog Posts</h2>
                    <div className="relative group-group">
                        <Slider ref={sliderRef} {...settings}>
                            {allBlogs.map((item, i) => (
                                <Link key={i} to={`/blog/${item?.handle}`} onClick={windowScroll} className='flex flex-col overflow-hidden bg-[#fff] group border rounded-[10px] duration-500 shadow-[0px_0px_5px_rgba(0,0,0,0.08)] justify-start'>
                                <div className='relative h-[260px] w-full overflow-hidden'>
                                  <img
                                    src={item?.image}
                                    alt={item?.altText}
                                    className='w-full h-full object-center object-cover duration-500 group-hover:rotate-[2deg] group-hover:scale-110'
                                  />
                                </div>
                                <div className='p-[25px]'>
                                  <ul className='mb-[20px] flex items-center'>
                                    <li className='flex items-center text-[15px] mr-[15px]'><FiUser className='inline-block text-[#309dd2] mr-[8px]'/> {item?.author}</li>
                                    <li className='flex items-center text-[15px]'><FaRegCalendarAlt className='inline-block text-[#309dd2] mr-[8px]'/> {item?.publishedAt}</li>
                                  </ul>
                                  <div>
                                    <h4 className='md:text-[24px] line-clamp-2 mb-[15px] group-hover:text-[#309dd2] duration-500 font-semibold leading-[28px]'>{item?.title}</h4>
                                    <p className='mb-[15px] relative line-clamp-3' dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                    <span className='text-[#000] group-hover:text-[#309dd2] duration-500'>Read More <RiArrowRightLine className='inline-block' /></span>
                                  </div>
                
                                </div>
                              </Link>
                            ))}
                        </Slider>
                        <button type="button" className='flex opacity-85 hover:opacity-100 items-center justify-center text-white duration-500 w-[40px] h-[40px] hover:bg-[#359dd0] bg-[#359dd0] cursor-pointer text-[20px] rounded-full absolute top-[50%] left-[-10px] -translate-y-2/4' onClick={() => sliderRef.current.slickPrev()}>
                            <HiOutlineArrowLeft />
                        </button>
                        <button type="button" className='flex opacity-85 hover:opacity-100 items-center justify-center text-white duration-500 w-[40px] h-[40px] hover:bg-[#359dd0] bg-[#359dd0] cursor-pointer text-[20px] rounded-full absolute top-[50%] right-[-10px] -translate-y-2/4' onClick={() => sliderRef.current.slickNext()}>
                            <HiOutlineArrowRight />
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog