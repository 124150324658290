import React, { useRef } from 'react';
// import Button from '../shared/button';
import ImageIcons from '../imageComponent/ImageIcons';
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Button from "../shared/button";

const Hero = () => {
    const sliderRef = useRef(null);

    let settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        Infinity: true
    };

    return (
        <section className="p-0 relative">
            <Slider ref={sliderRef} {...settings} >
                {bannerData.map((item, i) => (
                    <div key={i} className="relative after:content-[''] after:absolute after:left-0 after:right-0 after:top-0 after:w-full after:h-full after:bg-[#000] after:opacity-65">
                        <img className='w-full h-full object-cover top-0 left-0 lg:min-h-[650px] md:min-h-[550px] min-h-[520px]' src={ImageIcons.banner1} alt='' />

                        <div className='absolute top-[50%] z-20 left-0 -translate-y-2/4 w-full'>
                            <div className="text-center max-w-[900px] mx-auto px-[15px]">
                                <h1 className='text-white uppercase max-sm:text-[30px]'>{item.title}</h1>
                                <p className='text-white md:text-[18px] text-[17px] md:mb-[30px] mb-[20px]'>{item.text}</p>
                                <Button hrefLink='/' title='Learn More' />
                            </div>
                        </div>

                    </div>
                ))}
            </Slider>


            <div className='flex justify-center w-full absolute lg:bottom-[60px] bottom-[40px]'>
                <button type="button" className='h-[45px] w-[45px] bg-[#fff] duration-500 hover:bg-[#359dd0] hover:text-white text-[#359dd0] cursor-pointer flex justify-center items-center text-[25px]' onClick={() => sliderRef.current.slickPrev()}>
                    <IoIosArrowBack />
                </button>
                <button type="button" className='ml-[15px] h-[45px] w-[45px] bg-[#fff] duration-500 hover:bg-[#359dd0] hover:text-white text-[#359dd0] cursor-pointer flex justify-center items-center text-[25px]' onClick={() => sliderRef.current.slickNext()}>
                    <IoIosArrowForward />
                </button>
            </div>
        </section>
    )
}

const bannerData = [
    {
        title: "The Key to your Dream Living",
        text: "Get a comprehensive range of affordable and luxurious homes. We provide you with perfect cosy apartments customised to your needs.",
    },
    {
        title: "Invest Smartly and Secure Your Future",
        text: "Invest in prime locations and make a smart choice for your financial growth. Wait for what? Build your portfolio with Angan homes and make your investments flourish.",
    },
    {
        title: "Enjoy the Apex of Luxury Living",
        text: "Enjoy finest facilities and elegant designs with Angan homes’ premium properties. We, at Angan homes, elevate your lifestyle and live your dream life.",
    },

]

export default Hero
