import React from "react";
import Layout from "../components/shared/layout";
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';

const Gallery = () => {
  return (
    <Layout>
      <InnerPageBanner title='Gallery' innerPageBanner={ImageIcons} />

      <section>
        <div className="container">
          <h2 className='text-center mb-[30px]'>Gallery</h2>
          <div className='md:columns-3 columns-2 gap-x-[8px]'>
            <LightgalleryProvider>
              {images.map((item, index) => (
                <LightgalleryItem key={index} src={item.image} className="border-4 border-[#fff]">
                  <img className='w-full object-cover object-center mb-[8px] cursor-pointer' src={item.image} alt={item.alt} />
                </LightgalleryItem>
              ))}
            </LightgalleryProvider>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const images = [
  {
    image: ImageIcons.gallery1,
    alt: "Gallery image 1"
  },
  {
    image: ImageIcons.flatImg,
    alt: "Gallery image 1"
  },
  {
    image: ImageIcons.gallery4,
    alt: "Gallery image 1"
  },
  {
    image: ImageIcons.connected,
    alt: "Gallery image 1"
  },
  {
    image: ImageIcons.banner1,
    alt: "Gallery image 1"
  },
  {
    image: ImageIcons.flatImg,
    alt: "Gallery image 1"
  },
  {
    image: ImageIcons.gallery3,
    alt: "Gallery image 1"
  },
  {
    image: ImageIcons.gallery4,
    alt: "Gallery image 1"
  },

];

export default Gallery