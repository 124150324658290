import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';


const Gallery = () => {
    return (
        <section>
            <div className='container'>
                <h1 className='text-center mb-[30px]'>Gallery</h1>
                <div className='grid lg:grid-cols-4 grid-cols-2 lg:gap-[20px] gap-[10px]'>
                    <LightgalleryProvider>
                        {images.map((item, index) => (
                            <LightgalleryItem key={index} src={item.image} className="border-4 border-[#fff]">
                                <img className='xl:h-[400px] sm:h-[350px] h-[180px] w-full object-cover object-center cursor-pointer' src={item.image} alt={item.alt} />
                            </LightgalleryItem>
                        ))}
                    </LightgalleryProvider>
                </div>
            </div>
        </section>
    )
}

const images = [
    {
        image: ImageIcons.gallery1,
        alt: "Gallery image 1"
    },
    {
        image: ImageIcons.gallery2,
        alt: "Gallery image 1"
    },
    {
        image: ImageIcons.gallery3,
        alt: "Gallery image 1"
    },
    {
        image: ImageIcons.gallery4,
        alt: "Gallery image 1"
    },

];
export default Gallery