import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/home';
import Aboutus from './pages/aboutus';
import Contact from './pages/contact-us';
import Blog from './pages/blog';
import Properties from './pages/properties';
import BlogDetails from './pages/blogDetails';
import PrivacyPolicy from './pages/privacyPolicy';
import RefundPolicy from './pages/refundPolicy';
import TermsConditions from './pages/termsConditions';
import PropertiesDetails from './pages/propertiesDetails';
import NotFoundPage from './pages/404';
import DesignAndLayout from './pages/designAndLyout';
import Flats from './pages/3BhkFlats';
import Sitemap from './pages/sitemap';
import Home2 from './pages/home2';
import Gallery from './pages/gallery';
import Feedback from './pages/feedback';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/home2" exact element={<Home2 />} />
        <Route path="/about-us" exact element={<Aboutus />} />
        <Route path="/contact-us" exact element={<Contact />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/properties" exact element={<Properties />} />
        <Route path="/properties/properties-details" exact element={<PropertiesDetails />} />
        <Route path="/blog/:handle" exact element={<BlogDetails />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/refund-policy" exact element={<RefundPolicy />} />
        <Route path="/terms-and-conditions" exact element={<TermsConditions />} />
        <Route path="/design-and-layout" exact element={<DesignAndLayout />} />
        <Route path="/3-bhk-flats" exact element={<Flats />} />
        <Route path="/sitemap" exact element={<Sitemap />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/feedback" exact element={<Feedback />} />
        
        <Route path="*" exact element={<NotFoundPage />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
