import React from 'react'
import propertyimg2 from "../assets/images/2.jpg"
import propertyimg3 from "../assets/images/3.jpg"
import { FaBed, FaBath, FaMicrophone } from "react-icons/fa";
import { RiShapeFill } from "react-icons/ri";
import { IoLocationSharp } from "react-icons/io5";
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Layout from '../components/shared/layout';
import { Link } from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineLocalAirport, MdStar } from "react-icons/md";
import { TbBus } from "react-icons/tb";
import { IoSchoolSharp } from "react-icons/io5";
import { FaHospital } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { SiMcdonalds } from "react-icons/si";
import { FaUmbrellaBeach } from "react-icons/fa6";
import { ImSpoonKnife } from "react-icons/im";
import { IoShareSocial } from "react-icons/io5";
import { GiShoppingBag } from "react-icons/gi";
import { BsBuildingsFill } from "react-icons/bs";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { IoHomeOutline } from "react-icons/io5";
import { LiaCouchSolid } from "react-icons/lia";
import { CiParking1 } from "react-icons/ci";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { PiCalendarThin } from "react-icons/pi";
import { TbToolsKitchen3 } from "react-icons/tb";
import { PiParkThin } from "react-icons/pi";
import { GiFloorHatch } from "react-icons/gi";
import { RiCommunityLine } from "react-icons/ri";
import { HiOutlineHomeModern } from "react-icons/hi2";
import { IoBedOutline } from "react-icons/io5";
import { HiOutlineLightBulb } from "react-icons/hi2";
import { LiaClipboardListSolid } from "react-icons/lia";
import { AiOutlineSafetyCertificate } from "react-icons/ai";






const PropertiesDetails = () => {
  return (
    <Layout>
      <InnerPageBanner title='Properties Details' maxWidth='472px' innerPageBanner={ImageIcons.innerbannerbg} />
      <section>
        <div className='container'>
          <div className="flex max-lg:flex-wrap justify-between xl:gap-[40px] gap-[30px]">
            <div className="lg:w-[70%] w-full border-2 border-[#ededed] md:p-[35px] p-[25px]">


              <h2>Unlocking the Secrets to Smart Property Investment</h2>
              <div className="mb-[30px] flex max-sm:flex-col md:items-center gap-4 md:gap-8">
                <div className="flex items-center"><FaCalendarAlt className="text-[#e63d25] inline-block mr-[8px]" /> 25 April 2024</div>
                <div className="flex items-center"><FaLocationDot className="text-[#e63d25] inline-block mr-[8px]" /> Chandigarh-Patiala Highway, Zirakpur</div>
              </div>

              <div className='h-[400px] relative overflow-hidden mb-[40px]'>
                <img className='h-full w-full object-cover object-center' src={ImageIcons.propertyInvestment} alt="" />
              </div>

              <h4 className='mb-[30px] mt-[40px] border-l-2 border-[#e63d25] pl-3 font-bold leading-[30px]'>Description</h4>
              <p>Irrefutably, property investment seems to be a lucrative venture, there is a need for proper and careful planning and research before investing. Whether you are a first-time buyer or a seasoned investor, you need to have proper strategies in place, so that you can make informed decisions regarding your investment. It is crucial to understand the basics of smart property investment in order to maximise returns and minimise the risks associated with it. After conducting the proper research on the trends in the market and selecting the right properties, it becomes easier for the investors to achieve their financial goals.</p>
              <p>However, it is very difficult to find a reliable consultant in this dynamic world of real estate. A right consultant can pave the way towards your dream property but the wrong one would prove to be totally opposite of that. For easing your research and adding convenience to your life, we welcome you to the Aangan Realty, a preeminent real estate company in Zirakpur. We are committed to offer you a unique experience of buying and selling properties in the beautiful and growing region of Zirakpur. Aangan realty emerges as the game changer, as we not only assist you in finding an <strong>apartment house, independent house for rent, commercial plots for rent, and apartments for rent</strong> of your choice but also help in <strong>selling property.</strong></p>

              <h4 className='mb-[30px] mt-[40px] border-l-2 border-[#e63d25] pl-3 font-bold leading-[30px]'>Property Details</h4>
              <div className='grid sm:grid-cols-2 gap-[25px] mt-[30px]'>
                {PropertyDetailsData.map((item, i) => (
                  <div key={i} className='flex'>
                    <div className='min-w-[45px]'>
                      <span className='text-[26px]' >{item.icon}</span>
                    </div>

                    <div>
                      <h6 className='mb-0'>{item.title}</h6>
                      <p className='mb-0'>{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>

              <h4 className='mb-[30px] mt-[40px] border-l-2 border-[#e63d25] pl-3 font-bold leading-[30px]'>Proposed Plan</h4>
              <ul>
                <li className='flex items-center mb-[15px]'><MdStar className='inline-block mr-[8px]' />3 BHK with Store</li>
                <li className='flex items-center mb-[15px]'><MdStar className='inline-block mr-[8px]' />Super Area - 1320 Sq. Ft.</li>
                <li className='flex items-center mb-[15px]'><MdStar className='inline-block mr-[8px]' />Covered Area 1090 Sq. Ft.</li>
              </ul>

              <h4 className='mb-[30px] mt-[40px] border-l-2 border-[#e63d25] pl-3 font-bold leading-[30px]'>Flat Layout - With Lift</h4>
              <div>
                <img src={ImageIcons.flatLayout} alt='' className='w-full' />
              </div>

              <h4 className='mb-[30px] mt-[40px] border-l-2 border-[#e63d25] pl-3 font-bold leading-[30px]'>Always Connected.</h4>
              <div className='grid sm:grid-cols-2 gap-[25px] mt-[30px]'>
                {connectedData.map((item, i) => (
                  <div key={i} className='flex'>
                    <div className='min-w-[50px]'>
                      <span className='text-[26px]' >{item.icon}</span>
                    </div>

                    <div>
                      <h6 className='mb-0'>{item.title}</h6>
                    </div>
                  </div>
                ))}
              </div>

              <h4 className='mb-[30px] mt-[40px] border-l-2 border-[#e63d25] pl-3 font-bold leading-[30px]'>Related Properties</h4>
              <div className='grid sm:grid-cols-1 md:grid-cols-2 max-lg:gap-[20px] lg:gap-[30px]'>
                <div className='bg-[#fff] shadow-[0px_0px_25px_0px_rgba(0,0,0,0.15)] group overflow-hidden'>
                  <div className='feature-prd-pic relative cursor-pointer overflow-hidden h-[280px]'>
                    <img src={propertyimg2} alt='' className='group-hover:scale-[1.1] duration-500 w-full h-full object-center object-cover' />
                    <div className='bg-[#e63d25] text-[#fff] absolute top-[10px] left-[10px] px-[10px] py-[5px]'>
                      <span className='text-[13px]'>FOR SALE</span>
                    </div>
                  </div>
                  <div className='feature-prd-info p-4 mt-10px'>
                    <div>
                      <span class="text-[20px] text-[#e63d25] font-bold">₹45,0000 </span><span class="text-[17px] text-[#b0b0b0]">/mo</span>
                    </div>
                    <h3 className='text-[22px] mb-[10px]'>Comfortable Apartment</h3>
                    <p className='text-[15px]'>Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood</p>
                    <div className='flex flex-wrap gap-[15px] mb-[15px] border-b-[1px] pb-[15px]'>
                      <div className='border-r-[2px] pr-[10px]'>
                        <div>
                          <span className='flex gap-[10px] items-center'><FaBed className='text-[#b0b0b0]' size={22} />3</span>
                        </div>
                        <p className='text-[15px] text-[#b0b0b0] mb-[0px]'>Bedrooms</p>
                      </div>
                      <div className='border-r-[2px] pr-[10px]'>
                        <div>
                          <span className='flex gap-[10px] items-center'><FaBath className='text-[#b0b0b0]' size={20} />3</span>
                        </div>
                        <p className='text-[15px] text-[#b0b0b0] mb-[0px]'>Bathrooms</p>
                      </div>
                      <div className=''>
                        <div>
                          <span className='flex gap-[10px] items-center'><RiShapeFill className='text-[#b0b0b0]' size={22} />1230</span>
                        </div>
                        <p className='text-[15px] text-[#b0b0b0] mb-[0px]'>square Ft</p>
                      </div>
                    </div>
                    <div>
                      <p className='flex text-[15px] gap-[5px] mb-[0px]'><IoLocationSharp className='text-[#b0b0b0]' size={22} /> West County Road 550 North, United States</p>
                    </div>
                  </div>
                </div>
                <div className='bg-[#fff] shadow-[0px_0px_25px_0px_rgba(0,0,0,0.15)] group overflow-hidden'>
                  <div className='feature-prd-pic relative cursor-pointer overflow-hidden h-[280px]'>
                    <img src={propertyimg3} alt='' className='group-hover:scale-[1.1] duration-500 w-full h-full object-center object-cover' />
                    <div className='bg-[#e63d25] text-[#fff] absolute top-[10px] left-[10px] px-[10px] py-[5px]'>
                      <span className='text-[13px]'>FOR SALE</span>
                    </div>
                  </div>
                  <div className='feature-prd-info p-4 mt-10px'>
                    <div>
                      <span class="text-[20px] text-[#e63d25] font-bold">₹45,0000 </span><span class="text-[17px] text-[#b0b0b0]">/mo</span>
                    </div>
                    <h3 className='text-[22px] mb-[10px]'>Beautiful Flat in Manhattan</h3>
                    <p className='text-[15px]'>Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood</p>
                    <div className='flex flex-wrap gap-[15px] mb-[15px] border-b-[1px] pb-[15px]'>
                      <div className='border-r-[2px] pr-[10px]'>
                        <div>
                          <span className='flex gap-[10px] items-center'><FaBed className='text-[#b0b0b0]' size={22} />3</span>
                        </div>
                        <p className='text-[15px] text-[#b0b0b0] mb-[0px]'>Bedrooms</p>
                      </div>
                      <div className='border-r-[2px] pr-[10px]'>
                        <div>
                          <span className='flex gap-[10px] items-center'><FaBath className='text-[#b0b0b0]' size={20} />3</span>
                        </div>
                        <p className='text-[15px] text-[#b0b0b0] mb-[0px]'>Bathrooms</p>
                      </div>
                      <div className=''>
                        <div>
                          <span className='flex gap-[10px] items-center'><RiShapeFill className='text-[#b0b0b0]' size={22} />1230</span>
                        </div>
                        <p className='text-[15px] text-[#b0b0b0] mb-[0px]'>square Ft</p>
                      </div>
                    </div>
                    <div>
                      <p className='flex text-[15px] gap-[5px] mb-[0px]'><IoLocationSharp className='text-[#b0b0b0]' size={22} /> West County Road 550 North, United States</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="lg:w-[30%] w-full">
              <div className='mb-[40px] md:p-[35px] p-[25px] border-2 border-[#ededed]'>
                <div className='title-sidebar mb-5'>
                  <h4 className="font-bold border-l-2 border-[#e63d25] pl-3">Top Rated Properties</h4>
                  <hr className="green-hr-line" />
                </div>

                <Link className={`mt-[30px] flex group`} to='#'>
                  <div className={`flex justify-center items-center h-[90px] w-[90px] duration-500 relative rounded-[10px] overflow-hidden `}>
                    <img className={`h-full w-full object-cover object-center duration-500 group-hover:rotate-[5deg] group-hover:scale-150 `} src={ImageIcons.propertyInvestment} alt="" />
                  </div>
                  <div className={`md:pl-[10px] md:ml-[10px] pl-[7px] ml-[7px] text-left sm:w-[calc(100%_-_90px)] w-[calc(100%_-_90px)] `}>
                    <div className='text-[#e63d25] mb-[5px]'>25 April 2024</div>
                    <h5 className="mb-0 text-[18px] leading-[24px] line-clamp-2">Unlocking the Secrets to Smart Property Investment</h5>
                  </div>
                </Link>

                <Link className={`mt-[30px] flex group`} to='#'>
                  <div className={`flex justify-center items-center h-[90px] w-[90px] duration-500 relative rounded-[10px] overflow-hidden `}>
                    <img className={`h-full w-full object-cover object-center duration-500 group-hover:rotate-[5deg] group-hover:scale-150 `} src={ImageIcons.propertyInvestment} alt="" />
                  </div>
                  <div className={`md:pl-[10px] md:ml-[10px] pl-[7px] ml-[7px] text-left sm:w-[calc(100%_-_90px)] w-[calc(100%_-_90px)] `}>
                    <div className='text-[#e63d25] mb-[5px]'>25 April 2024</div>
                    <h5 className="mb-0 text-[18px] leading-[24px] line-clamp-2">"5 Must-Know Tips for First-Time Home Buyers"</h5>
                  </div>
                </Link>

                <Link className={`mt-[30px] flex group`} to='#'>
                  <div className={`flex justify-center items-center h-[90px] w-[90px] duration-500 relative rounded-[10px] overflow-hidden `}>
                    <img className={`h-full w-full object-cover object-center duration-500 group-hover:rotate-[5deg] group-hover:scale-150 `} src={ImageIcons.propertyInvestment} alt="" />
                  </div>
                  <div className={`md:pl-[10px] md:ml-[10px] pl-[7px] ml-[7px] text-left sm:w-[calc(100%_-_90px)] w-[calc(100%_-_90px)] `}>
                    <div className='text-[#e63d25] mb-[5px]'>25 April 2024</div>
                    <h5 className="mb-0 text-[18px] leading-[24px] line-clamp-2">Unlocking the Secrets to Smart Property Investment</h5>
                  </div>
                </Link>
              </div>

              <div className='overflow-hidden '>
                <div className='contact-sidebar-info'>
                  <div className='contact-img-gril border-2 border-[#e8edf3]'>
                    <img src={ImageIcons.customerCare} alt='blog' className='w-full' />
                  </div>
                  <div className=' bg-[linear-gradient(-6deg,#e63d25_74.9%,transparent_75.2%)] linear-gradient(-6deg, #0b7132 74.9%, #fff0 75.2%) relative text-center xl:px-10 xl:pb-10 max-xl:px-8 max-xl:pb-8 mt-[-80px]'>
                    <div className='relative z-20 w-[90px] h-[90px] mx-auto bg-[#fff] flex items-center justify-center rounded-full'>
                      <FaMicrophone className='text-[#e63d25] text-[35px]' />
                    </div>
                    <h4 className='mb-3 mt-4 text-white font-medium'>Do you need any help?</h4>

                    <p className='font-medium text-white cursor-pointer mb-[5px]'> <Link to="tel:+91 9988599485" >+91 9988599485</Link></p>
                    <p className='font-medium text-white cursor-pointer mb-0'><Link to="mailto:info@aanganhomes.com">info@aanganhomes.com</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </Layout>
  )
}
const connectedData = [
  {
    title: "International Airport - 10 Mins",
    icon: <MdOutlineLocalAirport />,
  },
  {
    title: "Zirakpur Bus - Stand - 5 Mins",
    icon: <TbBus />,
  },
  {
    title: "Schools - 3 Mins",
    icon: <IoSchoolSharp />,
  },
  {
    title: "Hospitals - 5 Mins",
    icon: <FaHospital />,
  },
  {
    title: "Casmo Mall - 8 Mins",
    icon: <FaShoppingCart />,
  },
  {
    title: "Mc Donalds - 8 Mins",
    icon: <SiMcdonalds />,
  },
  {
    title: "Opposite Apple Resort",
    icon: <FaUmbrellaBeach />,
  },
  {
    title: "Gopal Sweets - 1 Mins",
    icon: <ImSpoonKnife />,
  },
  {
    title: "HLP Social Square - 2 Mins",
    icon: <IoShareSocial />,
  },
  {
    title: "Elante Mall - 15 Mins",
    icon: <GiShoppingBag />,
  },
  {
    title: "JLPL - 20 Mins",
    icon: <BsBuildingsFill />,
  },
  {
    title: "D-Mart - 8 Mins",
    icon: <BiSolidPurchaseTag />,
  },
]
const PropertyDetailsData = [
  {
    title: "Home Type",
    icon: <IoHomeOutline />,
    text: "Condominium",
  },
  {
    title: "Interior Spaces",
    icon: <LiaCouchSolid />,
    text: "Living Room Combination Kitchen and Dining Room",
  },
  {
    title: "Parking",
    icon: <CiParking1 />,
    text: "2 Open Parking Spaces Paved Parking Parking Lot",
  },
  {
    title: "Est. Annual Taxes",
    icon: <AiOutlineDollarCircle />,
    text: "$512",
  },
  {
    title: "Year Built",
    icon: <PiCalendarThin />,
    text: "2024",
  },
  {
    title: "Kitchen",
    icon: <TbToolsKitchen3 />,
    text: "Electric Oven or Range Microwave",
  },
  {
    title: "Outdoor Features",
    icon: <PiParkThin />,
    text: "Water Oriented Property is near an ocean",
  },
  {
    title: "Flooring",
    icon: <GiFloorHatch />,
    text: "Wood Carpet",
  },
  {
    title: "Unit Details",
    icon: <RiCommunityLine />,
    text: "Top Floor Corner Lot",
  },
  {
    title: "Home Design",
    icon: <HiOutlineHomeModern />,
    text: "Bungalow Stick Built Home",
  },
  {
    title: "Bedrooms and Bathrooms",
    icon: <IoBedOutline />,
    text: "2 Main Level Bedrooms 1 Full Bathroom",
  },
  {
    title: "Utilities",
    icon: <HiOutlineLightBulb />,
    text: "Cooling System Mounted In Outer Wall Opening Wall Furnace Electric Water Heater",
  },
  {
    title: "Listing and Financial Details",
    icon: <LiaClipboardListSolid />,
    text: "Assessor Parcel Number 334-20.13-44.00-6",
  },
  {
    title: "Security System",
    icon: <AiOutlineSafetyCertificate />,
    text: "24x7 Security",
  },
  
]
export default PropertiesDetails