import React from "react";
import Layout from "../components/shared/layout";
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const RefundPolicy = () => {
  return (
    <Layout>
      <InnerPageBanner title='Refund Policy' maxWidth='472px' innerPageBanner={ImageIcons.refundPolicy} />

      <section>
        <div className="container">
          <p>Angan homes ensure providing top quality real estate services to the customers. Our refund policy provides information about the conditions in which refund is being issued to the user.</p>

          <h3 className="mb-[10px]">1. Eligibility for Refunds</h3>
          <p>Refunds are provided under the given circumstances-</p>

          <ul className="mb-[30px]">
            <li className="mb-[8px]"><strong>Before Agreement signing: </strong>If you decide to cancel your booking before signing the agreement, you will be eligible for refunds.</li>
            <li className="mb-[8px]"><strong>Non-fulfillment of terms: </strong>In case we fail to fulfil our conditions of sale agreement, you will be provided a refund.</li>
            <li className="mb-[8px]"><strong>Project Delays: </strong>If any of the project is delayed by us, you will be provided a refund.
            </li>
          </ul>

          <h3 className="mb-[10px]">2. Non-refundable payments</h3>
          <p>In some cases, users are not provided with a refund and those payments are mentioned below-</p>

          <ul className="mb-[30px]">
            <li className="mb-[8px]"><strong>Administrative Fees: </strong>The fees related to booking or cancellation process fall under the category of non-refundable payments.</li>
            <li className="mb-[8px]"><strong>Third-party Costs: </strong>The cost incurred by third parties like legal fees are non-refundable.</li>
            <li className="mb-[8px]"><strong>Partially Utilised Services: </strong>The services which have been used partially, their fees are non-refundable.
            </li>
          </ul>

          <h3 className="mb-[10px]">3. Refund Process</h3>
          <p>In order to request a refund, you need to follow certain steps-</p>

          <ul className="mb-[30px]">
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> Firstly, you have to submit the refund request, which you can do by contacting our customer support team via phone or email. You will need to provide your booking reference number along with providing a valid reason for the request.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> Your request will be reviewed by us and you will be sent a confirmation mail where the amount and method of refund will be mentioned.
            </li>
            <li className="relative pl-[25px]"><IoCheckmarkDoneSharp className="absolute top-[3px] left-0 mr-[6px] text-[#774503] inline-block mb-[8px]" /> The processing time of the refund is approximately 30 business days once the request is being approved. After the mentioned time period, the amount will be credited to your used  payment method
            </li>
          </ul>

          <h3 className="mb-[10px]">4. Contact Information</h3>
          <p>If you have any questions regarding our refund policy, you can contact us via phone, email and text message. The contact information is given  on our website.</p>

          <h3 className="mb-[10px]">5. Amendments to this Policy</h3>
          <p>We reserve the right to make changes to this policy at any time. If any changes happen, you will be notified about the same. We also encourage you to review our policy from time to time. By using our services, you show your agreement to our policies.</p>

          <p className="mb-0"><i>Thankyou for choosing Angan homes for your property needs.</i></p>
        </div>
      </section>
    </Layout>
  )
}

export default RefundPolicy